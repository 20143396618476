<template>
  <div :class="className" :style="{height:height,width:width}" />
</template>

<script>
import util from "../../../../../util/date";
import echarts from 'echarts'
import {GetColumnChartDataGroupByClassifyAndWeek,GetPeopleTaskNumberByTodayLogData,GetPostNodeUserViewPageByUserId} from '../../../../api/oa'
require('echarts/theme/macarons') // echarts theme
import resize from './mixins/resize'
import date from '../../../../../util/date';

const animationDuration = 2000

export default {
  mixins: [resize],
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '330px'
    }
  },
  data() {
    return {
      chart: null,
      listone: [],
      listtwo: [],
      listthree: [],
      departusers:[]
    }
  },
  methods(){

  },
  mounted() {
    this.$nextTick(() => {
      this.initChart()
    })
    var user = JSON.parse(window.localStorage.user);
    GetPostNodeUserViewPageByUserId({userId: user.sub, pageNum:1,pageSize:1000}).then(res=>{
      if(res.data.data!=null)
      {
        var usersdata = res.data.data.list;
        // console.log('users:');
        // console.log(usersdata);
        let users=this.unique(usersdata);
        var arr=new Array();
        var namearr=new Array();
        for(var n=0;n<users.length;n++)
        {
          // console.log(users[n]);
          arr.push(users[n].id);
          namearr.push(users[n].name);
        }
        this.departusers=namearr;
        GetPeopleTaskNumberByTodayLogData({peoples:arr,date:util.formatDate.format(new Date(),'yyyy/MM/dd'),d:-1}).then(res=>{
          this.listone = res.data.response[0];
          this.listtwo = res.data.response[1];
          this.listthree = res.data.response[2];
          this.initChart()
        });
      }
    });
  },
  beforeDestroy() {
    if (!this.chart) {
      return
    }
    this.chart.dispose()
    this.chart = null
  },
  methods: {
    unique(arr) {
      if(arr!=null)
      {
        const res = new Map();
        return arr.filter((arr) => !res.has(arr.id) && res.set(arr.id, 1));
      }
    },
    initChart() {
      this.chart = echarts.init(this.$el, 'macarons')

      this.chart.setOption({
        tooltip: {
          trigger: 'axis',
          axisPointer: { // 坐标轴指示器，坐标轴触发有效
            type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
          }
        },
        grid: {
          top: 10,
          left: '2%',
          right: '2%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [{
          type: 'category',
          data: this.departusers,
          axisTick: {
            alignWithLabel: true
          }
        }],
        yAxis: [{
          type: 'value',
          axisTick: {
            show: false
          }
        }],
        series: [{
          name: '创建任务',
          type: 'bar',
          stack: 'vistors',
          barWidth: '60%',
          data: this.listone,
          animationDuration
        }, {
          name: '增加进度',
          type: 'bar',
          stack: 'vistors',
          barWidth: '60%',
          data: this.listtwo,
          animationDuration
        }, {
          name: '完成任务',
          type: 'bar',
          stack: 'vistors',
          barWidth: '60%',
          data: this.listthree,
          animationDuration
        }]
      })
    }
  }
}
</script>
